/* eslint-disable no-console */
// This is our custom Freedom Debt Relief Plugin for getanalytics.io
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import { EVENT_ACTION } from '../../constants/analytics'
import { extractApplicationData } from '../utils/extractApplicationData'
import { extractEventData } from '../utils/extractEventData'
import { extractExperiments } from '../utils/extractExperiments'
import { extractIds } from '../utils/extractIds'
import { extractMetaTags } from '../utils/extractMetaTags'
import { extractUrlData } from '../utils/extractUrlData'
import { extractUserConsent } from '../utils/extractUserConsent'
import { extractUserDeviceData } from '../utils/extractUserDeviceData'

const getTealiumData = () => {
  // TODO - Perhaps we should do a waitFor here, in case the script hasn't loaded?
  const returnValue = {}
  if (window.utag) {
    returnValue.tealium_visitor_id = window.utag.data.tealium_visitor_id
    returnValue.tealium_session_id = window.utag.data.tealium_session_id
  }

  return returnValue
}

const generateKafkaPayload = (
  payload,
  eventName,
  eventAction,
  { application, shouldExtractApplicationData }
) => {
  const applicationData = shouldExtractApplicationData
    ? extractApplicationData(payload)
    : payload?.properties?.application_data

  return {
    ...extractEventData(payload, application),
    application_data: applicationData,
    application_data_string: JSON.stringify(applicationData),
    event_action: eventAction,
    event_datetime: Date.now(),
    event_id: uuidv4(),
    event_name: eventName,
    experiments: extractExperiments(payload),
    ids: {
      ...extractIds(payload),
      ...getTealiumData()
    },
    meta_tags: {
      ...extractMetaTags(payload)
    },
    url_data: {
      ...extractUrlData(payload)
    },
    user_consent: {
      ...extractUserConsent(payload)
    },
    user_device_data: {
      ...extractUserDeviceData(payload)
    }
  }
}

const analyticsFreedom = ({
  trackingUrl,
  userConfig,
  application,
  shouldExtractApplicationData,
  isDebug
}) => {
  const pushEvent = async (payload) => {
    if (!trackingUrl) {
      return
    }

    try {
      await axios.post(trackingUrl, payload)
    } catch (e) {
      if (isDebug) {
        console.error('analytics-freedom: track event failed')
      }
    }
  }

  return {
    config: Object.assign({}, userConfig),
    name: 'analytics-freedom',
    page: ({ payload }) => {
      const eventName = payload.properties.page_name + ' - Page Loaded'

      const payloadToSend = generateKafkaPayload(
        payload,
        eventName,
        EVENT_ACTION.PAGE_VIEW,
        { application, shouldExtractApplicationData }
      )

      pushEvent(payloadToSend)

      if (isDebug) {
        console.info('ANALYTICS :: freedom :: page - ', eventName, payloadToSend)
      }
    },
    track: ({ payload, instance }) => {
      // Track events don't have pageData by default, so we have to merge it in
      const pageDataProperties = instance.getState('page')?.last?.properties
      payload.properties = {
        ...pageDataProperties,
        ...payload.properties
      }
      const eventName = payload.properties.page_name + ' - ' + payload.event

      const payloadToSend = generateKafkaPayload(
        payload,
        eventName,
        payload.properties.event_action,
        { application, shouldExtractApplicationData }
      )

      pushEvent(payloadToSend)

      if (isDebug) {
        console.info('ANALYTICS :: freedom :: track - ', eventName, payloadToSend)
      }
    }
  }
}

export { analyticsFreedom, generateKafkaPayload }
