import { pick } from 'lodash'
import { EVENT_FIELDS } from '../../constants/analytics'

export const extractEventData = (payload, application) => {
  const picked = pick(payload.properties, [
    EVENT_FIELDS.EVENT_ID,
    EVENT_FIELDS.EVENT_ORIGIN
  ])

  return {
    ...picked,
    application,
    [EVENT_FIELDS.EVENT_DATETIME]: payload.meta.timestamp,
    [EVENT_FIELDS.EVENT_TYPE]: payload.type
  }
}
