/* eslint-disable no-console */
// This is our custom debug plugin for getanalytics.io
import _ from 'lodash'

import { REQUIRED_EVENT_FIELDS } from '../constants/analytics'

const checkRequiredFields = (payload) => {
  _.values(REQUIRED_EVENT_FIELDS).forEach((field) => {
    if (!payload.properties[field]) {
      console.error(
        'Missing required field on analytics call: ',
        field,
        payload.properties
      )
    }
  })
}

const analyticsDebug = () =>
  // return object for analytics to use
  ({
    identify: ({ payload }) => {
      console.info('ANALYTICS :: identify', payload)
    },

    /* Everything else below this is optional depending on your plugin requirements */
    initialize: ({ config }) => {
      console.info('ANALYTICS :: initialize', config)
    },

    loaded: () => true,
    /* All plugins require a name */
    name: 'debug-plugin',
    page: ({ payload }) => {
      checkRequiredFields(payload)
      console.info('ANALYTICS :: page', payload)
    },
    track: ({ payload }) => {
      checkRequiredFields(payload)
      console.info('ANALYTICS :: track', payload)
    }
  })

export { analyticsDebug }
