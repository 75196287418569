// This is our custom Tealium Plugin for getanalytics.io

import { extractCommonPayload } from '../utils/extractCommonPayload'
import { waitFor } from '../utils/waitFor'
import { pick } from 'lodash'

const utag = () => window.utag

const formatPayload = (application, fields, payload, type) => {
  const props = payload?.properties
  const allData = props?.application_data
  const applicationData = fields ? pick(allData, fields) : allData
  const pageName = props?.page_name
  const eventAction = type === 'page' ? 'page_view' : props?.event_action
  const eventName = type === 'page' ? 'Page Loaded' : payload.event

  return {
    ...applicationData,
    ...extractCommonPayload(payload, application),
    event_action: eventAction,
    event_name: `${pageName} - ${eventName}`,
    tealium_event: eventAction
  }
}

const analyticsTealium = ({ userConfig, application, includedFields }) => ({
  config: Object.assign({}, userConfig),
  name: 'analytics-tealium',
  page: ({ payload }) => {
    waitFor(utag, () => {
      const payloadToSend = formatPayload(
        application,
        includedFields,
        payload,
        'page'
      )
      utag().view(payloadToSend)
      // eslint-disable-next-line no-console
      console.info(
        'ANALYTICS :: tealium :: page - ',
        payloadToSend?.eventName,
        payloadToSend
      )
    })
  },
  track: ({ payload, instance }) => {
    // Track events don't have pageData by default, so we have to merge it in
    const pageDataProperties = instance.getState('page')?.last?.properties
    payload.properties = {
      ...pageDataProperties,
      ...payload?.properties
    }
    waitFor(utag, () => {
      const payloadToSend = formatPayload(
        application,
        includedFields,
        payload,
        'track'
      )
      utag().link(payloadToSend)
      // eslint-disable-next-line no-console
      console.info(
        'ANALYTICS :: tealium :: link - ',
        payloadToSend?.eventName,
        payloadToSend
      )
    })
  }
})

export { analyticsTealium }
