import Analytics from 'analytics'
import originalSrc from 'analytics-plugin-original-source'

const createAnalytics = ({ application, plugins = [], ...options } = {}) =>
  Analytics({
    app: application,
    plugins: [originalSrc(), ...plugins],
    ...options
  })

export { createAnalytics }
