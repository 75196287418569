import {
  getOriginalSource,
  getOriginalLandingPage
} from 'analytics-plugin-original-source'
import { mapKeys, pick } from 'lodash'

import { EVENT_FIELDS } from '../../constants/analytics'
import { getUtmParams } from './getUtmParams'

const extractUrlData = (payload) => {
  const originalSources = mapKeys(
    getOriginalSource(),
    (value, key) => 'original_utm_' + key
  )

  const { properties = {} } = payload
  const { search, path, hash } = properties

  const picked = pick(payload.properties, [EVENT_FIELDS.URL])

  return {
    ...picked,
    ...originalSources,
    ...getUtmParams(search),
    [EVENT_FIELDS.URL_PATH]: path,
    [EVENT_FIELDS.URL_SEARCH]: search,
    [EVENT_FIELDS.URL_HASH]: hash,
    [EVENT_FIELDS.ORIGINAL_LANDING_PAGE]: getOriginalLandingPage()
  }
}

export { extractUrlData }
