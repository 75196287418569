import crypto from 'crypto'

const US_COUNTRY_CODE = 1

export const hashEmail = (email) => {
  if (!email) {
    return null
  }

  return crypto
    .createHash('sha256')
    .update(email.trim().toLowerCase())
    .digest('hex')
}

export const hashPhone = (phone) => {
  if (!phone) {
    return null
  }

  return crypto
    .createHash('sha256')
    .update(US_COUNTRY_CODE + phone.replace(/[^.0-9]/g, ''))
    .digest('hex')
}
