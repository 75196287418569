import { pick } from 'lodash'
import { parseCookies } from 'nookies'
import { either, prop } from 'ramda'

import { EVENT_FIELDS } from '../../constants/analytics'
import { convertProfileToAnalyticsPayload } from './pageUtils'

const extractIds = (payload) => {
  const cookies = parseCookies()

  const leadId = either(
    prop('leadUUID'),
    prop('id')
  )(payload.properties.profile)

  const profile = convertProfileToAnalyticsPayload(payload.properties.profile)
  const picked = pick(profile, [
    EVENT_FIELDS.CEREBRO_PID,
    EVENT_FIELDS.FDR_APPLICANT_ID
  ])

  return {
    ...picked,
    [EVENT_FIELDS.GA_CLIENT_ID]: cookies._ga,
    [EVENT_FIELDS.GA_SESSION_ID]: cookies._gid,
    [EVENT_FIELDS.LEAD_UUID]: leadId || payload.anonymousId
  }
}

export { extractIds }
