import _ from 'lodash'

import { EVENT_FIELDS } from '../../constants/analytics'
import PROFILE_FIELDS from '../../constants/profileFields'

const profileToAnalyticsMapping = {
  [PROFILE_FIELDS.ACTUAL_DEBT]: EVENT_FIELDS.ACTUAL_DEBT,
  [PROFILE_FIELDS.ADDRESS]: EVENT_FIELDS.FORM_ADDRESS,
  [PROFILE_FIELDS.CEREBRO_PID]: EVENT_FIELDS.CEREBRO_PID,
  [PROFILE_FIELDS.CITY]: EVENT_FIELDS.FORM_CITY,
  [PROFILE_FIELDS.COAPP_CREDIT_PULL_ATTEMPTS_REMAINING]:
    EVENT_FIELDS.COAPP_CREDIT_PULL_ATTEMPTS_REMAINING,
  [PROFILE_FIELDS.COAPP_DATE_OF_BIRTH]: EVENT_FIELDS.FORM_COAPP_DATE_OF_BIRTH,
  [PROFILE_FIELDS.COAPP_FIRST_NAME]: EVENT_FIELDS.FORM_COAPP_FIRST_NAME,
  [PROFILE_FIELDS.COAPP_LAST_NAME]: EVENT_FIELDS.FORM_COAPP_LAST_NAME,
  [PROFILE_FIELDS.CREDIT_PULL_ATTEMPTS_REMAINING]:
    EVENT_FIELDS.CREDIT_PULL_ATTEMPTS_REMAINING,
  [PROFILE_FIELDS.CREDIT_REPORT_ID]: EVENT_FIELDS.CREDIT_REPORT_ID,
  [PROFILE_FIELDS.CURRENT_OR_PAST_DUE]: EVENT_FIELDS.FORM_DAYS_BEHIND,
  [PROFILE_FIELDS.DATE_OF_BIRTH]: EVENT_FIELDS.FORM_DATE_OF_BIRTH,
  [PROFILE_FIELDS.EMAIL]: EVENT_FIELDS.FORM_EMAIL,
  [PROFILE_FIELDS.ESTIMATED_DEBT]: EVENT_FIELDS.FORM_ESTIMATED_DEBT_INITIAL,
  [PROFILE_FIELDS.FDR_APPLICANT_ID]: EVENT_FIELDS.FDR_APPLICANT_ID,
  [PROFILE_FIELDS.FDR_ELIGIBLE]: EVENT_FIELDS.FDR_ELIGIBLE,
  [PROFILE_FIELDS.FIRST_NAME]: EVENT_FIELDS.FORM_FIRST_NAME,
  [PROFILE_FIELDS.IDENTITY_ANSWERED]:
    EVENT_FIELDS.FORM_IDENTITY_QUESTIONS_ANSWERED,
  [PROFILE_FIELDS.LAST_NAME]: EVENT_FIELDS.FORM_LAST_NAME,
  [PROFILE_FIELDS.LENDAGE_ELIGIBLE]: EVENT_FIELDS.LENDAGE_ELIGIBLE,
  [PROFILE_FIELDS.PAYMENT_DATE]: EVENT_FIELDS.FORM_PAYMENT_DATE,
  [PROFILE_FIELDS.PHONE]: EVENT_FIELDS.FORM_PHONE,
  [PROFILE_FIELDS.SOCIAL_SECURITY]: EVENT_FIELDS.FORM_SOCIAL_SECURITY,
  [PROFILE_FIELDS.STATE]: EVENT_FIELDS.FORM_STATE,
  [PROFILE_FIELDS.SUIP_ELIGIBLE]: EVENT_FIELDS.SUIP_ELIGIBLE,
  [PROFILE_FIELDS.ZIPCODE]: EVENT_FIELDS.FORM_ZIPCODE
}

const convertProfileToAnalyticsPayload = (profile) =>
  _.omitBy(
    _.mapKeys(profile, (value, key) => profileToAnalyticsMapping[key]),
    (value) => !value
  )

export { convertProfileToAnalyticsPayload, profileToAnalyticsMapping }
