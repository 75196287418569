const waitFor = (test, callback, count = 0) => {
  const MAX_COUNT = 20
  const MILLISECONDS_BETWEEN_RETRIES = 100

  const callMyself = (updatedCount) => () => {
    waitFor(test, callback, updatedCount)
  }

  // eslint-disable-next-line no-unreachable-loop
  while (!test()) {
    count++
    if (count < MAX_COUNT) {
      setTimeout(callMyself(count), MILLISECONDS_BETWEEN_RETRIES)
    }

    return
  }

  callback()
}

export { waitFor }
