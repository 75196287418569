import { pick } from 'lodash'
import { EVENT_FIELDS } from '../../constants/analytics'

const extractUserConsent = (payload) =>
  pick(payload.properties.user_consent, [
    EVENT_FIELDS.CONSENT_ID,
    EVENT_FIELDS.CONSENT_ID_SOURCE,
    EVENT_FIELDS.CONSENT_MEDIA,
    EVENT_FIELDS.CONSENT_SCOPE,
    EVENT_FIELDS.CONSENT_TEXT
  ])

export { extractUserConsent }
