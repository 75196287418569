import {
  getOriginalSource,
  getOriginalLandingPage
} from 'analytics-plugin-original-source'
import { mapKeys, pick } from 'lodash'
import { parseCookies } from 'nookies'
import { getUtmParams } from './getUtmParams'

const extractCommonPayload = (payload, application) => {
  const originalSources = mapKeys(
    getOriginalSource(),
    (value, key) => 'original_utm_' + key
  )

  const cookies = parseCookies()

  const whitelistedProperties = pick(payload.properties, [
    'campaign',
    'cerebro_id',
    'cta_text',
    'cta_value',
    'click_url',
    'event_id',
    'event_origin',
    'event_type',
    'page_name'
  ])

  return {
    ...whitelistedProperties,
    application,
    browser_height: payload.properties.height,
    browser_width: payload.properties.width,
    event_datetime: payload.meta.timestamp,
    event_type: payload.type,
    ga_client_id: cookies._ga,
    ga_session_id: cookies._gid,
    lead_uuid: payload.userId || payload.anonymousId,
    meta_title: payload?.properties?.title,
    original_landing_page: getOriginalLandingPage(),
    url: payload.properties.url,
    url_hash: payload.properties.hash,
    url_path: payload.properties.path,
    url_search: payload.properties.search,
    user_agent: navigator.userAgent,
    ...getUtmParams(payload.properties.search),
    ...originalSources
  }
}

export { extractCommonPayload }
